import {Person} from '../person/person-resource';
import {analytics} from './analytics';
import isNode from 'detect-node';

/**
 * Aliases the user.
 * @param personState
 */
export async function alias(personState: {
    person?: Person;
    isLoggedIn: boolean;
}): Promise<void> {
    if (!personState.isLoggedIn) {
        throw new Error('We can only execute alias on a logged in user');
    }
    if (isNode) {
        throw new Error("We can't call alias on node");
    }
    await analytics.alias(personState.person.id.toString());
}
