import Cookies from 'universal-cookie';
import {PREFERENCES_COOKIE} from '../constants';
import {isDev} from '../utilities/is-dev';
import {getCurrentDomain} from '../utilities/current-domain';
import {Preferences} from './preferences';
import {serializePreferences} from './serialize-user-preferences';

/**
 * Updates the user preferences in the given cookies object
 * @param cookies
 * @param userPreferences
 */
export const updateUserPreferences = (
    cookies: Cookies,
    userPreferences: Preferences
): void => {
    const serializedPreferences = serializePreferences(userPreferences);
    if (serializedPreferences) {
        const cookieOptions = {
            maxAge: 63072000,
            path: '/',
            httpOnly: false,
            overwrite: true,
            secure: !isDev,
            sameSite: true,
            domain: getCurrentDomain(),
        };
        cookies.set(PREFERENCES_COOKIE, serializedPreferences, cookieOptions);
    }
};
