/**
 * Loads a remote script.
 * Creates a script element with the provided src and attaches it to the head element
 * @param src Script source ex. https://example.com/xss.js
 * @param id unique identifier for the dom element
 * @param onLoad Optional, a callback to be called when the script is loaded
 * @param onError Optional, a callback to be called if the script fails
 */
export function loadScript(
    src: string,
    id: string,
    onLoad?: () => void,
    onError?: (e?: ErrorEvent) => void
): void {
    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('async', '');
    scriptEl.src = src;
    scriptEl.id = id;
    scriptEl.addEventListener('load', onLoad);
    scriptEl.addEventListener('error', onError);
    document.head.appendChild(scriptEl);
}

/**
 * async (Promise) version of loadScript
 * @param src
 * @param id
 */
export function loadScriptAsync(src: string, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
        loadScript(
            src,
            id,
            function handleScriptLoad() {
                resolve();
            },
            function handleScriptLoadError() {
                reject(new Error(`Could not load ${src}`));
            }
        );
    });
}
