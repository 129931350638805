import {PREFERENCES_COOKIE_SEPARATOR} from '../constants';
import {Preferences} from './preferences';

/**
 * Serializes user's preferences into a string which is ready to be saved as a cookie.
 * IMPORTANT: If the preferences contain no useful data, it returns an empty string
 * @param userPreferences
 */
export const serializePreferences = (userPreferences: Preferences): string => {
    const _values = [];
    if (userPreferences.currency) {
        _values.push(`currency=${userPreferences.currency}`);
    }
    if (userPreferences.lang) {
        _values.push('lang=' + userPreferences.lang);
    }
    if (userPreferences.signedup !== undefined) {
        _values.push(`signedup=${userPreferences.signedup}`);
    }
    if (userPreferences.hascontacts !== undefined) {
        _values.push(`hascontacts=${userPreferences.hascontacts}`);
    }
    return _values.join(PREFERENCES_COOKIE_SEPARATOR);
};
