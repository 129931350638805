import Cookies from 'universal-cookie';
import {getUserPreferences} from './get-user-preferences';
import {Preferences} from './preferences';
import {updateUserPreferences} from './update-user-preferences';

function comparePreferences(a: Preferences, b: Preferences): boolean {
    return (
        a.currency === b.currency &&
        a.lang === b.lang &&
        a.signedup === b.signedup &&
        a.hascontacts === b.hascontacts
    );
}
/**
 * Sets the default values of the preferences cookie
 * Existing prefs will preveil
 * @param cookies
 * @param defaults
 */
export function initDefaultPreferences(
    cookies: Cookies,
    defaults: Preferences
): Preferences {
    console.log("Getting user's preferences");
    const current = getUserPreferences(cookies);
    if (comparePreferences(current, defaults)) return current;
    const merged = {...defaults, ...current};
    updateUserPreferences(cookies, merged);
    return merged;
}
