import React from 'react';
import Script from 'next/script';
import {SCRIPT_SRC} from './analytics';

// Load segment's analytics using Next Script
export const AnalyticsScript = (): JSX.Element => {
    const [shouldRenderScript, setShouldRenderScript] = React.useState(false);

    // This effect handles the relationship between the cookie consent
    // and segment (analytics). Until the user hasn't consented to the use of cookies
    // we will not insert segment's script.
    React.useEffect(() => {
        const handleCookieBotResponse = () => {
            // Check if the user accepted all cookies.
            // TODO: Add granularity to the segment's integrations
            // we load depending on the user's cookies. For example,
            // if the user only accepted statistics cookies, load amplitude,
            // but if they also accepted marketing load Google, Facebook and the rest.
            // TODO: We should try to load intercom no matter what, however,
            // they will set their own cookies and this could cause trouble.
            if (
                window.Cookiebot &&
                window.Cookiebot.consented &&
                window.Cookiebot.consent.preferences &&
                window.Cookiebot.consent.statistics &&
                window.Cookiebot.consent.marketing
            ) {
                setShouldRenderScript(true);
            }
            window.removeEventListener(
                'CookiebotOnAccept',
                handleCookieBotResponse
            );
        };

        if (process.env.NEXT_PUBLIC_USE_COOKIE_BOT === '1') {
            // Check if the user has already accepted the cookies
            if (window.Cookiebot && window.Cookiebot.hasResponse) {
                handleCookieBotResponse();
            } else {
                window.addEventListener(
                    'CookiebotOnAccept',
                    handleCookieBotResponse
                );
            }
        } else {
            // If we are not using cookie bot, we load segment's script immediately
            setShouldRenderScript(true);
        }
    }, []);

    if (!shouldRenderScript) return null;

    return (
        <Script
            strategy="afterInteractive"
            src={SCRIPT_SRC}
            data-testid="analytics"
            onLoad={() => {
                if (window.analytics) {
                    window.analytics.invoked = true;
                }
            }}
        />
    );
};
