import type {CookieSetOptions} from 'universal-cookie';
import {getCurrentDomain} from '../utilities/current-domain';
import {UTM_COOKIE_EXPIRES} from '../constants';

export function getUtmCookieOptions(): CookieSetOptions {
    const domain = getCurrentDomain();
    return {
        domain,
        path: '/',
        expires: new Date(new Date().getTime() + UTM_COOKIE_EXPIRES),
        sameSite: 'lax',
        secure: !domain.includes('mi'),
    };
}
