import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import Cookies from 'universal-cookie';
import type {CookieSetOptions} from 'universal-cookie';
import {UTM_COOKIE_NAME, UTM_NAMES} from '../modules/util/constants';

export type UtmMap = Record<string, string>;

// TODO: Move this to state
const cache: UtmMap = {};

export function parseQuery(query: string): {[key: string]: string} {
    const ret = {};
    const urlSearchObj = new URLSearchParams(query);
    urlSearchObj.forEach((value, key) => (ret[key] = value));
    return ret;
}

function parseCookie(cookieValue = ''): {[key: string]: string} {
    const parts = cookieValue.split(':'),
        ret = {};
    for (const part of parts) {
        const kv = part.split('=');
        ret[kv[0]] = kv[1];
    }
    return ret;
}

function stringifyParams(items: UtmMap): string {
    const parts = [''];
    for (const key in items) {
        parts.push(`${key}=${items[key]}`);
        parts.push(':');
    }
    parts[parts.length - 1] = '';
    return parts.join('');
}

function save(utms: UtmMap, cookies: Cookies, opts: CookieSetOptions): void {
    cookies.set(UTM_COOKIE_NAME, stringifyParams(utms), opts);
}

export function get(
    cookies: Cookies,
    queryString: string,
    cookieSetOptions: CookieSetOptions,
    names = UTM_NAMES
): UtmMap {
    if (!isEmpty(cache)) {
        return cache;
    }

    Object.assign(cache, pick(parseQuery(queryString), names));
    if (isEmpty(cache)) {
        Object.assign(
            cache,
            
            pick(parseCookie(cookies.get(UTM_COOKIE_NAME)), names)
        );
    } else {
        save(cache, cookies, cookieSetOptions);
    }

    return cache;
}

export function clear(): void {
    for (const k in cache) delete cache[k];
}
