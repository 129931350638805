/**
 * In this module we define several maps that are used to set the environment variables.
 * For all the maps, the keys should be local, stage and prod.
 */

const PROJECTS = {
    FONOMA: 'fonoma',
    PHONOMA: 'phonoma',
    RETOPE: 'retope',
    PRETOPE: 'pretope',
}

module.exports = {
  PROJECTS,
};
