import React from 'react';
import {DefaultSeo} from 'next-seo';
import useTranslation from 'next-translate/useTranslation';

/**
 * This component contains the default SEO configuration for all our pages (i.e. all pages have
 * this values unless you override some of them)
 * @constructor
 */
const FonomaDefaultSeo = (): JSX.Element => {
    const {t} = useTranslation('metas');
    const baseURL = process.env.NEXT_PUBLIC_ASSETS_URL.replace('/assets', '');
    const PROJECT_DISPLAY_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME_DISPLAY;
    const PROJECT_NAME = process.env.NEXT_PUBLIC_PROJECT_NAME as string;
    const ogTitle = PROJECT_NAME === 'fonoma' ? t('metas:og-title') : t('metas:teloa-og-title');
    const htmlDescription = PROJECT_NAME === 'fonoma' ? t('metas:html-description') : t('metas:teloa-html-description');
    const ogDescription = PROJECT_NAME === 'fonoma' ? t('metas:og-description') : t('metas:teloa-og-description');

    return (
        <DefaultSeo
            title={ogTitle}
            description={htmlDescription}
            facebook={{
                appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
            }}
            openGraph={{
                type: 'website',
                url: process.env.NEXT_PUBLIC_SERVER_BASE_URL,
                images: [
                    {
                        url: `${baseURL}/home/v2/facebook-opengraph.png`,
                    },
                ],
                description: ogDescription,
                site_name: PROJECT_DISPLAY_NAME,
                title: ogTitle,
            }}
            additionalMetaTags={[
                {
                    name: 'mobile-web-app-capable',
                    content: 'Yes',
                },
                {
                    name: 'apple-mobile-web-app-capable',
                    content: 'Yes',
                },
                {
                    name: 'apple-mobile-web-app-status-bar-style',
                    content: 'translucent-black',
                },
                {
                    name: 'apple-mobile-web-app-title',
                    content: `${PROJECT_DISPLAY_NAME} Web`,
                },
                {
                    name: 'robots',
                    content: 'index, follow',
                },
            ]}
        />
    );
};

export default FonomaDefaultSeo;
