import {useEffect} from 'react';
import {
    FACEBOOK_SDK_VERSION,
} from '../../util/constants';

import {usePersonDispatch, usePersonState} from '../context';
import {useFacebookProvider} from '../../../lib/identity-providers/facebook/use-facebook-provider';
import {facebookSignIn} from '../facebook-sign-in/facebook-sign-in';

/**
 * A component that triggers the auto login feature.
 * 
 * Once you have a provider with "auto login" capabilities, you can call the login/signin method 
 * in an effect callback. 
 * Use multiple effects if the process can happen in parallel
 * or chain the providers via try/catch or then/catch otherwise
 * 
 * @example
 * const [providerOneReady, providerOne] = useProviderOne()
 * const [providerTwoReady, providerTwo] = useProviderTwo()
 * useEffect(async () => {
 *     if(providerOneReady) {
 *         try {
 *             const authResult = await providerOne.signIn(auto: true);
 *             // call our backend with the authResult and update app state (dispatch)
 *             // if the dispatch is successful, the parent component will short-circuit and this will never render again
 *         } catch (e) {
 *             // failed for provider one. trying provider two
 *             if (providerTwoReady) {
 *                 try{
 *                    const authResult = await providerTwo.signIn(auto: true);
 *                    // the rest is pretty much the same as provider one
 *                 }
 *             }
 *         }
 *     }
 * }, [providerOneReady, providerOne, providerTwoReady, providerTwo])
 
 */
export function AutologinFlow(): null {
    const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
    const personDispatch = usePersonDispatch();
    const [fbReady, facebookProvider] = useFacebookProvider(
        FACEBOOK_APP_ID,
        FACEBOOK_SDK_VERSION
    );
    useEffect(
        function autoLoginFacebookEffectCallback() {
            if (fbReady) {
                facebookSignIn(facebookProvider, true)
                    .then(({signup, ...person}) =>
                        personDispatch({
                            type: 'person/LOGIN_SUCCESS',
                            person,
                            signup,
                        })
                    )
                    // We don't need to handle autologin errors.
                    .catch(() => {});
            }
        },
        [fbReady, facebookProvider, personDispatch]
    );
    return null;
}

export function AutologinHandler(): JSX.Element {
    const {isLoggedIn} = usePersonState();
    return isLoggedIn ? null : <AutologinFlow />;
}
