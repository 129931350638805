import {usePersonState} from '../person/context';
import {useRollbar} from '@rollbar/react';
import {useEffect} from 'react';

/**
 * This component handles updating the rollbar object with the user's data when it changes.
 * It also handles error reporting on the user's side
 */
export function RollbarPersonHandler(): JSX.Element {
    console.log('RollbarPersonHandler');
    const {isLoggedIn, person} = usePersonState();

    const rollbar = useRollbar();
    useEffect(
        () => {
            if (isLoggedIn) {
                rollbar.configure({
                    payload: {
                        person: {
                            id: person.id,
                            fullName: person.fullName,
                            email: person.email,
                            lang: person.lang,
                            loginType: person.type,
                            fnmClient: 'web',
                            currency: person.currency,
                            created: person.createdOn,
                        },
                    },
                });
            }
        },
        // We don't want to listen to changes on rollbar

        [
            isLoggedIn,
            person?.id,
            person?.fullName,
            person?.email,
            person?.lang,
            person?.type,
            person?.currency,
            person?.createdOn,
        ]
    );
    return null;
}
