import {AxiosResponse} from 'axios';
import {ApiResource, ApiReturn, NoErrorResponseType, Resource} from '../../lib/resources/resource';
import {BasePerson} from '../common/models';
import {FonomaError} from '../util/fonoma-error';

/**
 *  This is what a `get` to the `persons` endpoint returns.
 *  May other types be derived from this.
 */
export type Person = BasePerson & ApiResource;

export type PersonUtms = {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
};

/**
 * The (structure of the) data needed to create a Person
 *
 * As u can se, human DNA is irrelevant
 */
export type PersonCreateInput = {
    fullName: string;
    email: string;
    password: string;
    lang?: string;
    currency?: string;
} & PersonUtms;

/**
 * Some fields are never included in the `post` response
 */
export type PersonCreateOutput = Omit<
    Person,
    'registrationId' | 'requestedAvatarFilenameInGcs'
>;

export class AccountAlreadyExists extends FonomaError<ApiReturn> {}
export class DomainBlacklisted extends FonomaError<ApiReturn> {}
export class InvalidEmail extends FonomaError<ApiReturn> {}
export class NameTooShort extends FonomaError<ApiReturn> {}
export class PersonResource extends Resource<
    Person,
    never,
    PersonCreateInput,
    PersonCreateOutput
> {
    static readonly FONOMA_ACCOUNT_ALREADY_EXISTS = -2;
    static readonly FONOMA_BLACKLISTED_DOMAIN = -4;
    static readonly INVALID_EMAIL_ADDRESS = -9;
    static readonly NAME_TOO_SHORT = -10;

    translateToError(
        response: AxiosResponse<ApiResource>
    ): FonomaError<ApiReturn> | Error | NoErrorResponseType {
        const apiReturn = response.data.apiReturn;
        if (!apiReturn) {
            return super.translateToError(response);
        }

        const {code, msg} = apiReturn;
        switch (code) {
            case PersonResource.FONOMA_ACCOUNT_ALREADY_EXISTS:
                return new AccountAlreadyExists(msg, apiReturn);
            case PersonResource.FONOMA_BLACKLISTED_DOMAIN:
                return new DomainBlacklisted(msg, apiReturn);
            case PersonResource.INVALID_EMAIL_ADDRESS:
                return new InvalidEmail(msg, apiReturn);
            case PersonResource.NAME_TOO_SHORT:
                return new NameTooShort(msg, apiReturn);

            default:
                return new FonomaError(msg, apiReturn);
        }
    }
}
export const personResource = new PersonResource('persons');
