import {isDev} from './is-dev';

export const getCurrentDomain = (): string => {
    return `.${
        isDev
            ? process.env.NEXT_PUBLIC_SERVER_BASE_URL.split('.')
                  .slice(-2)
                  .join('.')
                  .slice(0, -5)
            : process.env.NEXT_PUBLIC_SERVER_BASE_URL.split('.').slice(-2).join('.')
    }`;
};
