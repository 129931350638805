import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {API_V1_URL, WEB_CLIENT_HEADER} from '../../modules/util/constants';
import {
    defaultAuthorizationProvider,
    AuthorizationProvider,
} from './authorization';

type Interceptor = (
    config: AxiosRequestConfig
) => AxiosRequestConfig | Promise<AxiosRequestConfig>;

export function defaultCustomoHeadersInterceptorFactory(
    authorizationProvider: AuthorizationProvider = defaultAuthorizationProvider,
    commonHeaders: Record<string, unknown> = {...WEB_CLIENT_HEADER}
): Interceptor {
    return function customHeadersInterceptor(config): AxiosRequestConfig {
        
        config.headers = {
            ...authorizationProvider.getAuthorizationHeader(),
            ...commonHeaders,
            ...config.headers,
        };
        return config;
    };
}

export function createAxiosInstance(
    apiBaseUrl: string = API_V1_URL,
    customHeadersInterceptor: Interceptor = defaultCustomoHeadersInterceptorFactory()
): AxiosInstance {
    const opts: AxiosRequestConfig = {baseURL: apiBaseUrl};
    const instance = axios.create(opts);
    instance.interceptors.request.use(customHeadersInterceptor as any);
    return instance;
}
