import Cookies from 'universal-cookie';
import {PREFERENCES_COOKIE, PREFERENCES_COOKIE_SEPARATOR} from '../constants';
import {Preferences} from './preferences';

/**
 * Given the current cookies, it returns the user preferences.
 * @param cookies
 * @param defaultPreferences
 */
export const getUserPreferences = (
    cookies: Cookies,
    defaultPreferences?: Preferences
): Preferences => {
    const prefCookie = cookies.get<string>(PREFERENCES_COOKIE);
    const userPrefs = {} as Preferences;
    if (prefCookie) {
        
        prefCookie.split(PREFERENCES_COOKIE_SEPARATOR).forEach((rawPref) => {
            const pref = rawPref.split('=');
            switch (pref[0]) {
                case 'lang':
                    pref[1] && (userPrefs.lang = pref[1]);
                    break;
                case 'currency':
                    pref[1] && (userPrefs.currency = pref[1]);
                    break;
                case 'signedup':
                    pref[1] && (userPrefs.signedup = pref[1] === 'true');
                    break;
                case 'hascontacts':
                    pref[1] && (userPrefs.hascontacts = pref[1] === 'true');
            }
        });
    }
    return defaultPreferences
        ? {...defaultPreferences, ...userPrefs}
        : userPrefs;
};
