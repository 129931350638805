import {useEffect, useRef, useState} from 'react';
import {GoogleIdentityProvider} from './google/google-provider';
import {loadScriptAsync} from '../load-script';
import {AppleIdentityProvider} from './apple/apple-provider';
import {useRollbar} from '@rollbar/react';

interface ProviderState<
    TIdentityProvider = AppleIdentityProvider | GoogleIdentityProvider
> {
    provider?: TIdentityProvider;
    initialized: boolean;
    error?: Error;
}

/**
 * Hook to expose an authentication provider instance.
 * Handles load and initialization.
 * This hook is intended to be used by the providers. See for example
 * useAppleProvider.
 *
 * This allows to wait for the provider to be ready in the "ui (react) layer"
 * because we load the provider platform on demand
 *
 * If something happen when loading and initializing google, the reason will be in the "error" slot
 *
 * @param initializeProvider: The function to initialize the provider.
 * @param jsId: The id of the DOM element where we should insert the load script.
 * @param jsSrc: The URL of provider JS library.
 * @param objectNameOnWindow: The name, global, of the provider JS object
 */
export function useIdentifyProvider<TIdentityProvider>(
    initializeProvider: () => Promise<TIdentityProvider>,
    jsId: string,
    jsSrc: string,
    objectNameOnWindow: string
): [boolean, TIdentityProvider, Error] {
    const [{initialized, provider, error}, setState] = useState<
        ProviderState<TIdentityProvider>
    >({
        initialized: false,
    });

    const mountedRef = useRef(true);
    
    const rollbar = useRollbar();

    useEffect(
        function initializeGoogleProviderEffectCallback() {
            mountedRef.current = true;

            function onError(error: Error): void {
                
                rollbar.error(
                    `Error loading script ${objectNameOnWindow}`,
                    error
                );
                mountedRef.current &&
                    setState({
                        initialized: false,
                        error: error,
                    });
            }

            function onLoad(): void {
                initializeProvider()
                    .then((provider) => {
                        mountedRef.current &&
                            setState({
                                provider,
                                initialized: true,
                            });
                    })
                    .catch(onError);
            }

            function handlerScriptError(): void {
                onError(new Error(`${objectNameOnWindow} script load error`));
            }

            // if the script isn't loaded, we start by loading it
            const el = document.getElementById(jsId);
            if (!el) {
                loadScriptAsync(jsSrc, jsId).then(onLoad).catch(onError);

                // case: when you use the hook more than once in the same tree
            } else if (!(objectNameOnWindow in window) && el) {
                el.addEventListener('load', onLoad);
                el.addEventListener('error', handlerScriptError);

                // if there is a (late) re-render for some reason, this will get the cached value
            } else {
                onLoad();
            }
            return (): void => {
                el?.removeEventListener('error', handlerScriptError);
                el?.removeEventListener('load', onLoad);
                mountedRef.current = false;
            };
        },
        [initializeProvider, jsId, jsSrc, objectNameOnWindow, rollbar]
    );

    return [initialized, provider, error];
}
