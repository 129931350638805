import styled, { useTheme } from 'styled-components';
import lottie from 'lottie-web/build/player/lottie_light';
import React, {useEffect, useRef} from 'react';
import {StyledProps} from '../modules/common';
import useTranslation from 'next-translate/useTranslation';
import {
    convertAlphaToHex,
    convertToHex,
} from '../modules/util/utilities/color-spaces-utils';
import {shadowBottomLongStyles} from './shadows.styles';

/**
 * Component to display fonoma loading indicator
 
 ***Props
 * opacity: indicates the opacity of the background
 - If no opacity is provided the background will be transparent
 * color: indicates the color of the background;
 - By default color is #000000 (black)
 * info: text to display
 -By default info is the 'loading' entrance in 'common' namespace
 
 Note:
 - The loading indicator is a lottie animation located in path: 'static/animations/spinner.json'
 - The components underneath the loading indicator are not clickable or scrollable when the loader indicator is visible,
 
 **/

export interface LoaderIndicatorProps extends StyledProps {
    opacity?: number;
    color?: string;
    info?: string;
}

const AnimationContainer = styled.div`
    width: 48px;
    color: ${(props): string => `${props.theme.colors.ballena}`};
`;

const LoaderCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 8px;

    background-color: ${(props): string => `${props.theme.colors.arroz}`};
    ${shadowBottomLongStyles}

    height: 166px;
    width: 166px;
`;

const StyledP = styled.p`
    margin-top: 8px;
    margin-bottom: 8px;
`;

const UnstyledLoadingIndicator = (props: LoaderIndicatorProps): JSX.Element => {
    const theme = useTheme();
    const ANIMATION_PATH = theme.loadingIndicator.animationPath;

    const {className, info} = props;
    const refContainer = useRef();
    const {t} = useTranslation('common');

    useEffect(() => {
        lottie.loadAnimation({
            container: refContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: ANIMATION_PATH,
        });

        //This modifies page STYLE to avoid scrolling the page when loading
        document.body.style.overflow = 'hidden';
        return (): void => {
            document.body.style.overflow = 'unset';
        };
    }, [ANIMATION_PATH]);

    const infoContent = info ? info : t('common:loading');

    return (
        <div className={className} data-testid="loading-indicator">
            <LoaderCard>
                <AnimationContainer ref={refContainer} />
                <StyledP>{infoContent}</StyledP>
            </LoaderCard>
        </div>
    );
};

const LoadingIndicator = styled(UnstyledLoadingIndicator)`
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    // bigger than the modal
    z-index: 2000;

    background-color: ${(props): string =>
        `${convertToHex(props.color)}${convertAlphaToHex(props.opacity)}`};
`;

LoadingIndicator.defaultProps = {
    opacity: 0,
    color: '#000000',
};

export {LoadingIndicator};
