import {Person} from '../person/person-resource';
import Cookies from 'universal-cookie';
import {get, parseQuery, UtmMap} from '../../lib/utm';
import {getUtmCookieOptions} from '../util/cookie-preferences/get-utm-cookie-options';
import {getCommonAnalyticsData, getTraits} from './utils';
import {analytics} from './analytics';
import isEmpty from 'lodash/isEmpty';
import isNode from 'detect-node';
import {TIKTOK_QUERY_NAME} from '../util/constants';

/**
 * Identifies an user.
 *
 * @param personState: The user's state.
 * @param initialUtms: The user's initial Utms (used only on signup).
 */
export async function identify(
    personState: {
        person?: Person;
        isLoggedIn: boolean;
    },
    initialUtms?: UtmMap
): Promise<void> {
    if (!personState.isLoggedIn) {
        throw new Error("We can't identify a user that is not logged in");
    }
    if (isNode) {
        throw new Error("We can't call identify on node");
    }
    const person: Person = personState.person;
    const cookies = new Cookies(document.cookie);
    const query = location.search;
    const utms = get(cookies, query, getUtmCookieOptions());
    const traits = getTraits(person);

    const options = {
        Intercom: {
            user_hash: person.intercomUserHashWeb,
        },
    };

    if (!isEmpty(initialUtms)) {
        // https://github.com/fonoma/web-app/blob/043ee50983599c117edef8076019702bad41df24/static/js/modules/auth/auth.service.js#L74
        Object.assign(traits, initialUtms);
    }
    const [props, opts] = getCommonAnalyticsData(person, utms, traits, options);

    props[TIKTOK_QUERY_NAME] =
        parseQuery(location.search)[TIKTOK_QUERY_NAME] || null;

    await analytics.identify(person.id.toString(), props, opts);
}
