import {
    AuthResult,
    AutloginFailure,
    IdentityProvider,
    IdentityProviders,
    UserCancelledFlow,
} from '../identity-provider';
import {FonomaError} from '../../../modules/util/fonoma-error';

export const FB_JS_ID = 'facebook-jssdk';
export const FB_JS_SRC = `https://connect.facebook.net/en_US/sdk.js`;

export interface FacebookSignInOptions {
    auto?: boolean;
}

/*
 * Facebook identity provider implementation
 */
export class FacebookIdentityProvider extends IdentityProvider<
    facebook.FacebookStatic,
    FacebookSignInOptions
> {
    // Because facebook is weird, we need to initialize the client everytime
    // we do a request.
    readonly version: string;
    readonly appId: string;

    constructor(
        client: facebook.FacebookStatic,
        appId: string,
        version: string
    ) {
        super(client);
        this.appId = appId;
        this.version = version;
    }

    /**
     * Facebook sign in
     *
     * Calls facebook.FacebookStatic.login with the defaults.
     * facebook's accessToken is in AuthResult.token
     *
     * Autologin:
     * If 'auto' option is true, checks if the facebook user is already signed in and retrieves the auth data
     * @param options {FacebookSignInOptions}
     */
    async signIn({
        auto = false,
    }: FacebookSignInOptions = {}): Promise<AuthResult> {
        return new Promise<AuthResult>((resolve, reject) => {
            this.init();
            if (auto) {
                FB.getLoginStatus((response) => {
                    if (response.status === 'connected') {
                        resolve(
                            this.createAuthResult(
                                response.authResponse.accessToken,
                                true
                            )
                        );
                    } else {
                        reject(
                            new AutloginFailure(
                                'Autologin failed',
                                IdentityProviders.Facebook
                            )
                        );
                    }
                });
            } else {
                FB.login(
                    (response) => {
                        if (response.status === 'connected') {
                            resolve(
                                this.createAuthResult(
                                    response.authResponse.accessToken,
                                    false
                                )
                            );
                        } else if (response.status === 'unknown') {
                            reject(
                                new UserCancelledFlow('User cancelled flow')
                            );
                        } else {
                            reject(
                                new FonomaError(
                                    'Login failed',
                                    IdentityProviders.Facebook
                                )
                            );
                        }
                    },
                    {scope: 'email'}
                );
            }
        });
    }

    private init() {
        FB.init({
            appId: this.appId, // App ID from the App Dashboard
            status: true, // check the login status upon init?
            cookie: true, // set sessions cookies to allow your server to access the session?
            version: this.version, // version of the API
            xfbml: true,
        });
    }

    private createAuthResult(idToken: string, autoLogin: boolean): AuthResult {
        return {
            token: idToken,
            kind: IdentityProviders.Facebook,
            isAutoLogin: autoLogin,
        };
    }
}
