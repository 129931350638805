/**
 * Module used for constants.
 *
 * Do not import anything here
 */

/**
 * Allowed languages
 */
const LANGUAGES = {
    SPANISH: 'es',
    ENGLISH: 'en',
};

const LOCALES = {
    SPANISH: 'es-419',
    ENGLISH: 'en-US',
};

/**
 * Fonoma environments
 */
const FONOMA_ENVS = {
    LOCAL: 'local',
    STAGE: 'stage',
    PRODUCTION: 'prod',
};

const CURRENCIES = {
    USD: 'USD',
    EUR: 'EUR',
    CUC: 'CUC',
};

const SERVER_BASE_URL = process.env.NEXT_PUBLIC_SERVER_BASE_URL;
const FACEBOOK_SDK_VERSION = 'v18.0';

const API_V1_PATH = '/api/v1/';

const API_V1_URL = `${SERVER_BASE_URL}${API_V1_PATH}`;

const FONOMA_CLIENT = 'web';
const WEB_CLIENT_HEADER = {'X-Client': FONOMA_CLIENT};

const JWT_COOKIE_NAME = 'jwt';

// used when the user checks 'remember', 365 days
const JWT_COOKIE_EXPIRES = 31536000 * 1000;

const UTM_COOKIE_NAME = '_fnmautm';
const UTM_COOKIE_EXPIRES = 63072000 * 1000;
const UTM_NAMES = [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_content',
    'utm_term',
    'utm_adset',
    'utm_banner',
];

const FACEBOOK_FBP_COOKIE_NAME = '_fbp';
const FACEBOOK_FBC_COOKIE_NAME = '_fbc';

const TIKTOK_QUERY_NAME = 'ttclid';

const INSTRUCTIONS_LINK =
    'https://docs.google.com/document/d/1tdjlpD1LFC6hxemWll9o9ltL1DqcUl64X-B67V1mlwc/edit?usp=sharing';

const PREFERENCES_COOKIE = 'prefs';

const PREFERENCES_COOKIE_SEPARATOR = ':';

const EVENTS = {
    // Login/Sign up related events
    LANGUAGE_CHANGED: 'Language changed',
    SIGNUP: 'Signup',
    LOGIN: 'Login',
    LOGIN_MODAL: 'Login modal',
    LOGIN_USER_CANCELLED: 'Login user cancelled',
    FULL_NAME_NEEDED_MODAL: 'Full name needed modal',
    EMAIL_NEEDED_MODAL: 'Email needed modal',
    PASSWORD_RESET_PAGE: 'Password reset page',
    PASSWORD_RESET: 'Password reset',
    PASSWORD_RESET_REQUEST: 'Password reset request',
    MISMATCH_EMAIL_AND_PERSON_TYPE_MODAL:
        'Mismatch email and person_type modal',
    LOGIN_IN_MISMATCH_EMAIL_AND_PERSON_TYPE_MODAL:
        'Login in mismatch email and person_type modal',
    // Gift related events
    PROMO_ACCEPTANCE: 'Promo acceptance',
    PROMO_ACCEPTANCE_FAILED: 'Promo acceptance failed',
    // Promo code related events
    PROMO_CODE_REDEEMED: 'Promocode Redeemed',
    REDEEM_PROMO_CODE_ERROR: 'Redeem promocode error',
    //Recharge request related events
    RECHARGE_REQUEST_ACCEPTED: 'Recharge request accepted',
};

const PAGE_EVENTS = {
    LOGIN_PAGE: 'Login',
    LOGIN_EMAIL_PAGE: 'Login Email Form',
    LOGIN_PASSWORD_PAGE: 'Login Password Form',
    LOGIN_PASSWORD_RESET_PAGE: 'Login Password Reset',
    LOGIN_PASSWORD_RESET_EMAIL_PAGE: 'Login Reset Password Email Sent',
    LOGIN_NEW_ACCOUNT_EMAIL_PAGE: 'Login New Account with Email',
    LOGIN_NEW_ACCOUNT_EMAIL_FORM_PAGE: 'Login New Account with Email Form',
    TERMS_PAGE: 'Terms',
    PRIVACY_PAGE: 'Privacy',
    ABOUT_PAGE: 'About',
};

const ROBOTO_FAMILIES = [300, 400, 700];

const TOAST_TYPES = {
    ERROR: 'error',
    SUCCESS: 'success',
};

export {
    LANGUAGES,
    FONOMA_ENVS,
    INSTRUCTIONS_LINK,
    PREFERENCES_COOKIE,
    PREFERENCES_COOKIE_SEPARATOR,
    CURRENCIES,
    EVENTS,
    PAGE_EVENTS,
    ROBOTO_FAMILIES,
    WEB_CLIENT_HEADER,
    API_V1_PATH,
    API_V1_URL,
    JWT_COOKIE_NAME,
    JWT_COOKIE_EXPIRES,
    UTM_COOKIE_NAME,
    UTM_COOKIE_EXPIRES,
    UTM_NAMES,
    FACEBOOK_FBP_COOKIE_NAME,
    FACEBOOK_FBC_COOKIE_NAME,
    TIKTOK_QUERY_NAME,
    FONOMA_CLIENT,
    FACEBOOK_SDK_VERSION,
    TOAST_TYPES,
    LOCALES,
};

