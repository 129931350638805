import Cookies from 'universal-cookie';
import {JWT_COOKIE_NAME} from '../util/constants';
import {jwtDecode} from 'jwt-decode';

export interface PersonId {
    id: number;
    jwt: string;
}

export function getPersonIdFromCookies(cookies: Cookies): PersonId | null {
    const jwt = cookies.get(JWT_COOKIE_NAME);
    console.log(`jwt is ${jwt}`);
    if (jwt) {
        // just decode the token
        try {
            const decoded = jwtDecode(jwt);
            if (
                decoded['person_id'] &&
                typeof decoded['person_id'] === 'number'
            )
                return {
                    id: decoded['person_id'],
                    jwt: jwt,
                };
        } catch (e) {
            console.error(e);
        }
    }
    return null;
}
