import {AxiosRequestConfig} from 'axios';
import {IdentityProviders} from '../../lib/identity-providers/identity-provider';
import {
    ApiResource,
    ApiReturn,
    CreateResource,
} from '../../lib/resources/resource';
import {FonomaError} from '../util/fonoma-error';
import {Person} from './person-resource';

export interface OauthSessionCreate {
    kind: IdentityProviders;
    autoLogin?: boolean;
    lang?: string;
    currency?: string;
    deviceId?: string;
    fbToken?: string;
    fbsrCookie?: string;
    email?: string;
    ggAuthToken?: string;
    apAccessToken?: string;
    apFullName?: string;
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
    discountInfo?: boolean;
    ipRestriction?: boolean;
}

export interface OauthSessionCreateResult extends Person {
    /**
     * Flags the server did a signup
     */
    signup?: boolean;
}

export class OauthSessionAutologinFailed extends FonomaError<ApiReturn> {}

export class OauthSessionMissingFacebookEmail extends FonomaError<ApiReturn> {
    fbToken: string;

    constructor(message: string, public wrapped: ApiReturn, fbToken: string) {
        super(message);
        this.fbToken = fbToken;
    }
}

export class OauthSessionAccountAlreadyExists extends FonomaError<ApiReturn> {}

export class OauthSessionBlacklistedDomain extends FonomaError<ApiReturn> {}

export class OauthSessionResource extends CreateResource<
    OauthSessionCreate,
    OauthSessionCreateResult
> {
    static readonly FONOMA_AUTOLOGIN_FAILED = 2;

    static readonly FONOMA_MISSING_FACEBOOK_EMAIL = 3;
    static readonly FONOMA_ACCOUNT_ALREADY_EXISTS = -2;
    static readonly FONOMA_BLACKLISTED_DOMAIN = -4;

    // TODO: Find a way to avoid this override... maybe we can get rid of the generic methods
    async create<
        TIn = OauthSessionCreate,
        TOut extends ApiResource = OauthSessionCreateResult
    >(model: TIn, options?: AxiosRequestConfig): Promise<TOut>;

    async create(
        model: OauthSessionCreate,
        options?: AxiosRequestConfig
    ): Promise<OauthSessionCreateResult> {
        let apiReturnOnFail;
        try {
            const res = await this.client.post<OauthSessionCreateResult>(
                `${this.name}`,
                model,
                options
            );
            if (res.data.apiReturn?.code !== 0) {
                apiReturnOnFail = res.data.apiReturn;
            } else {
                return res.data;
            }
        } catch (e) {
            if (e.response?.data?.apiReturn) {
                
                apiReturnOnFail = e.response?.data?.apiReturn;
            } else {
                throw e;
            }
        }
        
        throw OauthSessionResource.translateToError(apiReturnOnFail, model);
    }

    static translateToError(
        apiReturn: ApiReturn,
        model: OauthSessionCreate
    ):
        | OauthSessionAutologinFailed
        | OauthSessionMissingFacebookEmail
        | FonomaError<ApiReturn> {
        switch (apiReturn.code) {
            case OauthSessionResource.FONOMA_MISSING_FACEBOOK_EMAIL:
                return new OauthSessionMissingFacebookEmail(
                    apiReturn.msg,
                    apiReturn,
                    model.fbToken
                );
            case OauthSessionResource.FONOMA_AUTOLOGIN_FAILED:
                return new OauthSessionAutologinFailed(
                    apiReturn.msg,
                    apiReturn
                );
            case OauthSessionResource.FONOMA_ACCOUNT_ALREADY_EXISTS:
                return new OauthSessionAccountAlreadyExists(
                    apiReturn.msg,
                    apiReturn
                );
            case OauthSessionResource.FONOMA_BLACKLISTED_DOMAIN:
                return new OauthSessionBlacklistedDomain(
                    apiReturn.msg,
                    apiReturn
                );
            default:
                return new FonomaError<ApiReturn>(apiReturn.msg, apiReturn);
        }
    }
}

export const oauthSessionResource = new OauthSessionResource('oauth_sessions');
