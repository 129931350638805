import React from 'react';
import {PersonActionTypes, personReducer, PersonState} from './state';

export type PersonDispatch = (action: PersonActionTypes) => void;

export const PersonStateContext = React.createContext<PersonState | undefined>(
    undefined
);
export const PersonDispatchContext = React.createContext<
    PersonDispatch | undefined
>(undefined);

interface PersonProviderProps {
    children: React.ReactNode;
    initialState: PersonState;
}

export function PersonProvider({
    children,
    initialState,
}: PersonProviderProps): React.ReactElement<PersonProviderProps> {
    const [state, dispatch] = React.useReducer(personReducer, initialState);
    return (
        <PersonStateContext.Provider value={state}>
            <PersonDispatchContext.Provider value={dispatch}>
                {children}
            </PersonDispatchContext.Provider>
        </PersonStateContext.Provider>
    );
}

export function usePersonState(): PersonState {
    const ctx = React.useContext(PersonStateContext);
    if (ctx === undefined) {
        throw new Error('usePersonState must be used within PersonProvider');
    }
    return ctx;
}

export function usePersonDispatch(): PersonDispatch {
    const ctx = React.useContext(PersonDispatchContext);
    if (ctx === undefined) {
        throw new Error('usePersonDispatch must be used within PersonProvider');
    }
    return ctx;
}

// just a shortcut
export function usePerson(): [PersonState, PersonDispatch] {
    return [usePersonState(), usePersonDispatch()];
}
