import {Metric} from './metric-interface';

/**
 * Given a metric object, returns the name of the event we should track
 * @param metric
 */
export const getEventName = (metric: Metric): string => {
    switch (metric.name) {
        case 'FCP':
            return 'First Contentful Paint';
        case 'LCP':
            return 'Largest Contentful Paint';
        case 'CLS':
            return 'Cumulative Layout Shift';
        case 'FID':
            return 'First Input Delay';
        case 'TTFB':
            return 'Time to First Byte';
        default:
            return metric.name;
    }
};
