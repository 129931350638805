import type {CookieSetOptions} from 'universal-cookie';
import {getCurrentDomain} from '../utilities/current-domain';
import {JWT_COOKIE_EXPIRES} from '../constants';

export function getJwtCookieOptions(remember: boolean): CookieSetOptions {
    const domain = getCurrentDomain();
    const opts: CookieSetOptions = {
        domain: domain,
        path: '/',
        sameSite: 'lax',
        secure: !domain.includes('mi'),
    };
    if (remember) {
        opts.expires = new Date(new Date().getTime() + JWT_COOKIE_EXPIRES);
    }

    return opts;
}
