import {generateMedia} from 'styled-media-query';

// We are using here bootstrap breakpoints as defined here
// https://getbootstrap.com/docs/4.3/layout/overview/#responsive-breakpoints
// Note that phones in vertical position are there ones bellow the small breakpoint
export const mediaQueries = generateMedia({
    small: '576px', // Small devices (phones in landscape)
    medium: '768px', // Medium devices (tables)
    large: '992px', // Large devices (laptops)
    huge: '1200px', // Huge devices (big monitors)
});
