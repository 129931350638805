import {Person} from '../person/person-resource';
import isNode from 'detect-node';
import Cookies from 'universal-cookie';
import {get} from '../../lib/utm';
import {getUtmCookieOptions} from '../util/cookie-preferences/get-utm-cookie-options';
import {getCommonAnalyticsData} from './utils';
import {analytics} from './analytics';

/**
 * Tracks an event.
 *
 * You should use this function when you are sure that you have the latest
 * personState, on the contrary, use useTrack.
 *
 * @param personState: The user's state.
 * @param event: Event's name.
 * @param properties: Event properties.
 * @param options: Tracking options.
 */
export async function track(
    personState: {
        person?: Person;
        isLoggedIn: boolean;
    },
    event: string,
    properties?: Record<string, unknown>,
    options?: Record<string, unknown>
): Promise<void> {
    if (isNode) {
        throw new Error("We can't call track on node");
    }
    const person: Person = personState.person;
    const cookies = new Cookies(document.cookie);
    const query = location.search;
    const utms = get(cookies, query, getUtmCookieOptions());
    if (personState.isLoggedIn) {
        const [props, opts] = getCommonAnalyticsData(
            person,
            utms,
            properties,
            options
        );
        await analytics.track(event, props, opts);
    } else {
        await analytics.track(event, properties, options);
    }
}
