import {CURRENCIES, LANGUAGES} from '../constants';

export interface Preferences {
    lang?: string;
    currency?: string;
    signedup?: boolean;
    hascontacts?: boolean;
}

export const defaultPreferences: Preferences = {
    lang: LANGUAGES.SPANISH,
    currency: CURRENCIES.USD,
    signedup: false,
    hascontacts: false,
};
