// same configuration you would create for the Rollbar.js SDK
import {FONOMA_ENVS} from '../util/constants';

export const rollbarConfig = {
    accessToken: `${process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_ACCESS_TOKEN}`,
    environment: `${process.env.NEXT_PUBLIC_ROLLBAR_ENVIRONMENT}`,
    enabled: process.env.NEXT_PUBLIC_PROJECT_ENV !== FONOMA_ENVS.LOCAL,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: `"${process.env.NEXT_BUILD_ID}"`,
                guess_uncaught_frames: true,
            },
        },
    },
};
