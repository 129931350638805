import {
    createGlobalStyle,
} from 'styled-components';

import {mediaQueries} from '../modules/common/styles/media-queries-breakpoints';

/**
 * Global styles that will be applied to all our pages
 */
export const FonomaGlobalStyle = createGlobalStyle`
  body {
    letter-spacing: ${(props): string =>
        `${props.theme.globalLetterSpacing}${props.theme.fontMeasureUnit}`};
    font-size: ${(props): string =>
        `${props.theme.fontSizes.medium}${props.theme.fontMeasureUnit}`};
    font-family: ${(props): string => props.theme.fontFamily};
    margin: 0;
    padding-top: ${(props): string =>
        `${props.theme.desktopMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};

    .intercom-lightweight-app-launcher{
        background: ${(props): string => props.theme.colors.plancton};
    }

    ${mediaQueries.lessThan('small')`
    padding-top: ${(props): string =>
        `${props.theme.mobileMeasures.navBarHeight}${props.theme.unitsOfMeasure.px}`};
  `};
  }

  * {
    font-family: ${(props): string => props.theme.fontFamily};   
  }
  
  div{
  box-sizing: border-box;
  }
  
  a {
        text-decoration: none;
        color: ${(props): string => props.theme.colors.ballena};
    }
`;

