import {FacebookIdentityProvider} from './facebook-provider';

/**
 * Once initialized the client, we keep a cached value here
 */
let instance: FacebookIdentityProvider = null;

export const FACEBOOK_OBJECT_NAME_ON_WINDOW = 'FB';

export function initializeFacebookProvider(
    appId: string,
    version: string
): FacebookIdentityProvider {
    if (instance !== null) {
        return instance;
    }

    if (!(FACEBOOK_OBJECT_NAME_ON_WINDOW in window)) {
        throw new Error(
            'The facebook SDK script must be loaded before calling this function'
        );
    }

    // Initialize the FacebookStatic object
    instance = new FacebookIdentityProvider(FB, appId, version);
    setFacebookProviderInstance(instance);
    return instance;
}

/**
 * Sets the cached instance
 * @param provider The provider instance
 */
export function setFacebookProviderInstance(
    provider: FacebookIdentityProvider
): void {
    instance = provider;
}
