import {useCallback} from 'react';
import {
    FacebookIdentityProvider,
    FB_JS_ID,
    FB_JS_SRC,
} from './facebook-provider';
import {
    initializeFacebookProvider,
    FACEBOOK_OBJECT_NAME_ON_WINDOW,
} from './initialize-facebook-provider';
import {useIdentifyProvider} from '../base-use-provider';

/**
 * Hook to expose the facebook provider instance.
 * Handles facebook load and initialization.
 *
 * This allows to wait for facebook to be ready in the "ui (react) layer"
 * because we load the facebook platform on demand
 *
 * If something happen when loading and initializing facebook, the reason will be in the "error" slot
 *
 * @param appId
 * @param version
 */
export function useFacebookProvider(
    appId: string,
    version: string
): [boolean, FacebookIdentityProvider, Error] {
    const initializeFacebookProviderWrapper = useCallback(() => {
        return new Promise<FacebookIdentityProvider>((resolve) => {
            resolve(initializeFacebookProvider(appId, version));
        });
    }, [appId, version]);
    return useIdentifyProvider<FacebookIdentityProvider>(
        initializeFacebookProviderWrapper,
        FB_JS_ID,
        FB_JS_SRC,
        FACEBOOK_OBJECT_NAME_ON_WINDOW
    );
}
