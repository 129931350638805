export enum IdentityProviders {
    Google = 'gg',
    Facebook = 'fb',
    Apple = 'ap',
}

export interface AuthResult<TExtra = never> {
    /**
     * User access token from the provider
     */
    token: string;

    /**
     * Identifier for the provider
     */
    kind: IdentityProviders;

    /**
     * Defines If it was an auto login
     */
    isAutoLogin: boolean;

    /**
     * Any extra data for a specific provider
     */
    extra?: TExtra;
}

export type AllProvidersInstanceTypes =
    | google.accounts.oauth2.CodeClient
    | facebook.FacebookStatic
    | AppleSignInAPI.AuthI;

export abstract class IdentityProvider<
    TClient = AllProvidersInstanceTypes,
    TSignInOpts = {},
    TExtra = {}
> {
    /**
     *
     * @param client The underlying 3rd party client
     */
    constructor(public client: TClient) {}

    /**
     * Implementation should call the 3rd party sign in mechanism
     * @param options
     */
    abstract signIn(options?: TSignInOpts): Promise<AuthResult<TExtra> | void>;
}

/**
 * Error when client tries to "autologin" and the user is not auth with google
 */
export class AutloginFailure extends Error {
    constructor(
        public message: string,
        public readonly type?: IdentityProviders
    ) {
        super(message);
    }
}

/**
 * Error when the user cancels the flow (basically closes the model)
 */
export class UserCancelledFlow extends Error {}
