import isEmpty from 'lodash/isEmpty';
import Cookies from 'universal-cookie';
import type {CookieSetOptions} from 'universal-cookie';
import {
    defaultAuthorizationProvider,
    JWT_COOKIE_NAME,
} from '../../lib/resources/authorization';
import {UtmMap} from '../../lib/utm';
import {alias} from '../analytics/alias';
import {identify} from '../analytics/identify';
import {track} from '../analytics/track';
import {prefixInitialUtms} from '../analytics/utils';
import {EVENTS} from '../util/constants';
import {getJwtCookieOptions} from '../util/cookie-preferences/get-jwt-cookie-options';
import {getUserPreferences} from '../util/cookie-preferences/get-user-preferences';
import {updateUserPreferences} from '../util/cookie-preferences/update-user-preferences';
import {Person} from './person-resource';

export async function doSignup(
    person: Person,
    signupMethod: string,
    utms: UtmMap
): Promise<void> {
    await alias({person: person, isLoggedIn: true});

    let initialUtms = {};
    if (!isEmpty(utms)) {
        initialUtms = prefixInitialUtms(utms);
    }

    await identify({person: person, isLoggedIn: true}, initialUtms);
    await track({person: person, isLoggedIn: true}, EVENTS.SIGNUP, {
        signupMethod,
    });
}

interface DoLoginOptions {
    jwtCookieOptions: CookieSetOptions;
    remember?: boolean;
    autoLogin?: boolean;
    identified?: boolean;
}

export async function doLogin(
    person: Person,
    cookies: Cookies,
    {
        remember = false,
        autoLogin = false,
        identified = false,
        jwtCookieOptions,
    }: DoLoginOptions
): Promise<void> {
    // set the token cookie
    cookies.set(JWT_COOKIE_NAME, person.jwt, jwtCookieOptions);
    // this will force to rerun the auth resolver and invalidate cache
    defaultAuthorizationProvider.getAuthorizationHeader(false);

    // update prefs
    const prefs = {...getUserPreferences(cookies)};
    let updatePrefs = false;

    if (!prefs.signedup) {
        prefs.signedup = true;
        updatePrefs = true;
    }
    if (person.hasContacts) {
        prefs.hascontacts = true;
        updatePrefs = true;
    }
    if (updatePrefs) {
        updateUserPreferences(cookies, prefs);
    }

    // do analytics stuff
    if (!identified) {
        await identify({person: person, isLoggedIn: true});
    }

    await track({person: person, isLoggedIn: true}, EVENTS.LOGIN, {
        loginMethod: person.type,
        rememberMe: remember,
        autoLogin,
    });
}

export interface PostSigninOptions {
    /**
     * Identifies the provider used for signup
     */
    signupMethod?: 'facebook' | 'google' | 'fonoma' | 'apple';

    /**
     * Session persistence
     */
    remember?: boolean;

    /**
     * Indicates an auto login
     */
    autoLogin?: boolean;

    /**
     * Options for setting the session cookie (jwt)
     */
    jwtCookieOptions?: CookieSetOptions;
}

/**
 * Performs the following tasks after a successful login/signup (setting cookies, analytics, ...)
 *
 * @param person The `Person` returned from the server
 * @param utms Utm params from url/cookie
 * @param options {PostSigninOptions}
 * @param cookies {Cookies}
 */
export async function postSigninActions(
    person: Person,
    utms: UtmMap,
    options: PostSigninOptions = {},
    cookies = new Cookies(document.cookie)
): Promise<void> {
    const {
        autoLogin = false,
        remember = false,
        signupMethod,
        ...cookieOptions
    } = options;
    const jwtCookieOptions =
        cookieOptions.jwtCookieOptions ?? getJwtCookieOptions(remember);
    let identified = false;

    if (signupMethod) {
        await doSignup(person, signupMethod, utms);
        identified = true;
    }
    await doLogin(person, cookies, {
        jwtCookieOptions,
        remember,
        autoLogin,
        identified,
    });
}
