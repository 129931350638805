import Cookies from 'universal-cookie';

const AUTHZ_RESOLVER = Symbol('CLIENT_AUTHZ_RESOLVER');
const CACHED_HEADER = Symbol('CACHED_HEADERS');

export const JWT_COOKIE_NAME = 'jwt';

export type AuthzHeader = {Authorization?: string};
export type AuthzResolver = () => AuthzHeader;

export function getAuthzHeaderFromCookies(
    cookies: Cookies,
    name: string = JWT_COOKIE_NAME
): AuthzHeader {
    const jwt = cookies.get<string>(name);
    return jwt ? {Authorization: `Bearer ${jwt}`} : {};
}

export function cookieResolverFactory(cookies: Cookies): AuthzResolver {
    return function authzResolver(): AuthzHeader {
        return getAuthzHeaderFromCookies(cookies);
    };
}

/**
 * Singletons like this one are an anti-pattern.
 * This exist to simplify the data fetching API
 * ...
 */
export class AuthorizationProvider {
    private [AUTHZ_RESOLVER]: AuthzResolver;
    private static instance: AuthorizationProvider;
    private [CACHED_HEADER]: AuthzHeader = null;
    private constructor(resolver: AuthzResolver) {
        this[AUTHZ_RESOLVER] = resolver;
    }

    static getInstance(
        resolver: AuthzResolver = AuthorizationProvider.defaultAuthzResolver
    ): AuthorizationProvider {
        if (!AuthorizationProvider.instance) {
            AuthorizationProvider.instance = new AuthorizationProvider(
                resolver
            );
        }
        return AuthorizationProvider.instance;
    }

    private static defaultAuthzResolver: AuthzResolver = () => {
        return getAuthzHeaderFromCookies(new Cookies());
    };

    setAuthzResolver(resolver: AuthzResolver): void {
        this[AUTHZ_RESOLVER] = resolver;
        this[CACHED_HEADER] = null;
    }

    getAuthorizationHeader(useCache = true): AuthzHeader {
        if (this[CACHED_HEADER] !== null && useCache) {
            return this[CACHED_HEADER];
        }
        return (this[CACHED_HEADER] = this[AUTHZ_RESOLVER]());
    }
}

export const defaultAuthorizationProvider = AuthorizationProvider.getInstance();
