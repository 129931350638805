import {FacebookIdentityProvider} from '../../../lib/identity-providers/facebook/facebook-provider';
import {OauthSessionCreateResult} from '../oauth-session-resource';
import Cookies from 'universal-cookie';
import {personSignIn} from '../person-sign-in';
import {AuthResult} from '../../../lib/identity-providers/identity-provider';

export interface FacebookExtraData {
    email?: string;
}

export async function facebookSignIn(
    provider: FacebookIdentityProvider,
    autoLogin = false,
    email?: string
): Promise<OauthSessionCreateResult> {
    const authResult = await provider.signIn({auto: autoLogin});
    const cookies = new Cookies(document.cookie);
    const queryString = document.location.search;
    const newAuthResult = {...authResult} as AuthResult<FacebookExtraData>;
    if (email) {
        newAuthResult.extra = {
            email: email,
        };
    }
    return personSignIn<FacebookExtraData>(newAuthResult, {
        remember: true,
        cookies,
        queryString,
    });
}
